var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide"}},[_c('div',{staticClass:"add-modal"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(_vm.$route.query.id)?_c('h3',{staticClass:"modal-title"},[_vm._v("Update User")]):_c('h3',{staticClass:"modal-title"},[_vm._v("Add User")]),(_vm.errorMessagereset)?_c('div',{staticClass:"box-error"},[_c('div',{staticClass:"error_text"},[_vm._v(_vm._s(_vm.errorMessagereset))])]):_vm._e(),_c('hr'),_c('div',{staticClass:"field"},[_c('div',{staticClass:"img"},[(!_vm.imageData)?_c('img',{attrs:{"src":require("../../../public/images/user-avatar/user-avatar-02.png"),"alt":""},on:{"click":function($event){return _vm.uploadFile()}}}):_c('img',{attrs:{"src":_vm.imageData,"alt":""},on:{"click":function($event){return _vm.uploadFile()}}}),_c('i',{staticClass:"fas fa-camera"}),_c('input',{ref:"fileinput",attrs:{"hidden":"hidden","name":"image","type":"file"},on:{"input":_vm.onSelectFile}}),(!_vm.errorfileSize)?_c('label',[_vm._v(_vm._s(_vm.errorfileSize))]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.image),expression:"form.image"}],attrs:{"hidden":"hidden","name":"image","type":"text"},domProps:{"value":(_vm.form.image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "image", $event.target.value)}}}),_c('div')])]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("User Name")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"User Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"input",attrs:{"type":"text","required":""},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"12px"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("Email")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{staticStyle:{"width":"100%"},attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"input",attrs:{"type":"text","required":""},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('span',{staticStyle:{"font-size":"12px","color":"red"},attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),(!_vm.$route.query.id)?_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_vm._v("Password")]),_c('div',{staticClass:"control"},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"input",attrs:{"type":"text","required":""},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('span',{staticStyle:{"font-size":"12px","color":"red"},attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e(),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":""}},[_c('label',{staticClass:"error_text"}),_vm._v("Role")]),_c('div',{staticClass:"control"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.role),expression:"form.role"}],staticClass:"input is-focused",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "role", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',[_vm._v("Owner")]),_c('option',[_vm._v("Manager")])])])]),(_vm.$route.query.id)?_c('button',{staticClass:"button primary",on:{"click":function($event){return handleSubmit(_vm.updateUser)}}},[_vm._v(" Update ")]):_c('button',{staticClass:"button primary",on:{"click":function($event){return handleSubmit(_vm.addUser)}}},[_vm._v(" Send Invitation ")])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }