<template>
  <transition name="slide">
    <div class="add-modal">
      <ValidationObserver v-slot="{ handleSubmit }">
        <h3 v-if="$route.query.id" class="modal-title">Update User</h3>
        <h3 v-else class="modal-title">Add User</h3>
        <div v-if="errorMessagereset" class="box-error">
          <div class="error_text">{{ errorMessagereset }}</div>
        </div>
        <hr />
        <div class="field">
          <div class="img">
            <!-- <div v-if="!imageData" @click="uploadFile()"></div> -->
            <img
              v-if="!imageData"
              @click="uploadFile()"
              src="../../../public/images/user-avatar/user-avatar-02.png"
              alt=""
            />
            <!-- <div v-else :style="{ 'background-image': `url( ${imageData} )` }" @click="uploadFile()" alt="" ></div> -->
            <img v-else :src="imageData" @click="uploadFile()" alt="" />
            <i class="fas fa-camera"></i>

            <input
              hidden="hidden"
              name="image"
              ref="fileinput"
              type="file"
              @input="onSelectFile"
            />
            <!-- <h3 v-if="!errorfileSize">Please upload your company logo</h3> -->
            <label v-if="!errorfileSize">{{ errorfileSize }}</label>
            <!-- <p>Supported file .jpg .png .gif | Maximum 5mb</p> -->

            <input
              hidden="hidden"
              name="image"
              v-model="form.image"
              type="text"
            />
            <div></div>
          </div>
          <!-- <div class="img">
                        <img src="../../../public/images/user-avatar/user-avatar-02.png" alt="">
                        
                    </div> -->
        </div>
        <div class="field">
          <label for="" class="label">User Name</label>
          <div class="control">
            <ValidationProvider
              name="User Name"
              rules="required"
              v-slot="{ errors }"
            >
              <input type="text" v-model="form.name" class="input" required />
              <span style="color: red; font-size: 12px">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="field">
          <label for="" class="label">Email</label>
          <div class="control">
            <ValidationProvider
              name="email"
              rules="email"
              style="width: 100%"
              v-slot="{ errors }"
            >
              <input type="text" v-model="form.email" class="input" required />
              <span style="font-size: 12px; color: red" id="error">{{
                errors[0]
              }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="field" v-if="!$route.query.id">
          <label for="" class="label">Password</label>
          <div class="control">
            <ValidationProvider
              name="password"
              rules="required|min:8"
              v-slot="{ errors }"
            >
              <input
                type="text"
                v-model="form.password"
                class="input"
                required
              />
              <span style="font-size: 12px; color: red" id="error">{{
                errors[0]
              }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="field">
          <label for class="label"
            ><label class="error_text"></label>Role</label
          >
          <div class="control">
            <select v-model="form.role" class="input is-focused">
              <option>Owner</option>
              <option>Manager</option>
            </select>
          </div>
        </div>
        <button
          v-if="$route.query.id"
          @click="handleSubmit(updateUser)"
          class="button primary"
        >
          Update
        </button>
        <button v-else @click="handleSubmit(addUser)" class="button primary">
          Send Invitation
        </button>
      </ValidationObserver>
    </div>
  </transition>
</template>

<script>
import { ADD_USER, GET_USER, UPDATE_USER } from "@/graphql/User";
import apolloClient_Company from "@/services/apollo-client-company";
import axios from "axios";
export default {
  props: ["AddUser", "user"],
  data: () => ({
    imageData: "",
    userid: [],
    errorMessagereset: "",
    errorfileSize: "",
    form: {
      image: "",
      name: "",
      email: "",
      password: "",
      role: "",
    },
  }),
  created() {
    if (this.$route.query.id) {
      this.getUser();
    }
  },
  methods: {
    async updateUser() {
      try {
        const isConfirmed = await this.$dialog.confirm();
        if (!isConfirmed) return;
        await apolloClient_Company.mutate({
          mutation: UPDATE_USER,
          variables: {
            _id: this.$route.query.id,
            name: this.form.name,
            email: this.form.email,
            role: this.form.role,
            image: this.imageData,
          },
        });
        this.$router.back();
      } catch ({ graphQLErrors }) {
        const errorText = graphQLErrors[0].message;
        switch (errorText) {
          case "This email registered by anouther":
            return (this.errorMessagereset =
              "This email registered by anouther");
        }
      }
    },
    CloseModal() {
      this.$emit("closemo");
    },
    async getUser() {
      const res = await apolloClient_Company.query({
        query: GET_USER,
        variables: {
          id: this.$route.query.id,
        },
      });

      this.userid = res.data.editempUser;
      this.form.name = this.userid.fName;
      this.form.email = this.userid.email;
      this.form.role = this.userid.role;
      this.imageData = this.userid.image;
    },

    async addUser() {
      try {
        const isConfirmed = await this.$dialog.confirm();
        if (!isConfirmed) return;
        await apolloClient_Company.mutate({
          mutation: ADD_USER,
          variables: {
            image: this.imageData,
            name: this.form.name,
            email: this.form.email,
            password: this.form.password,
            role: this.form.role,
          },
        });
        this.$router.back();
      } catch ({ graphQLErrors }) {
        const errorText = graphQLErrors[0].message;
        switch (errorText) {
          case "This E-mail already registered":
            return (this.errorMessagereset = "This E-mail already registered");
        }
      }
    },
    chooseImage() {
      this.$refs.fileinput.click();
    },
    uploadFile() {
      this.$refs.fileinput.click();
    },
    async onSelectFile() {
      const input = this.$refs.fileinput;
      const files = input.files;

      const file = files[0];
      // console.log(size, "MB")

      const formData = new FormData();

      formData.append("file", file);
      // console.log(formData)
      if (file) {
        const filesize = file.size / 1024;

        const size = 5 * 1024;
        if (filesize < size) {
          axios.defaults.headers.common[
            "Authorization"
          ] = this.$store.getters.getCompanyToken;
          const res = await axios.post(`${this.$urlImage}`, formData);
          this.imageData = `${this.$urlImageShow}` + res.data.fileKey;
          if (res.data.message) {
            this.errorfileSize = "The file must be .JPG, .PNG. JPEG, .SVG";
            this.form.image = null;
            this.imageData = null;
          }
          if (res.data.fileKey) {
            this.errorfileSize = file.name;
            this.form.image = res.data.fileKey;
          }
        } else {
          this.errorfileSize = "The file Size is too much";
          this.imageData = null;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error_text {
  color: red;
}
.img {
  text-align: center;
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
}
button {
  margin-top: 20px;
}
.modal-title {
  font-size: 24px;
  font-weight: 700;
  color: var(--text-color);
}
hr {
  background-color: var(--grey-color);
  height: 1px;
  margin: 20px 0;
}

// checkbox
.custom-checkbox {
  margin-bottom: 8px;
  label {
    margin-left: 25px;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 18px;
      height: 18px;
      border: 2px solid var(--primary-color);
      transition: all ease-in-out 0.2s;
      z-index: 0;
      margin-left: -25px;
      left: 0;
    }
  }
  input {
    display: none;
    &:checked ~ label::before {
      background-color: var(--primary-color);
    }
    &:checked ~ label::after {
      content: "";
      position: absolute;
      left: -21px;
      top: 8px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
        4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
      transform: rotate(45deg);
    }
    &:checked ~ label {
      color: var(--primary-color);
    }
  }
}

.slide-enter-active {
  animation: modal 0.2s;
}

.slide-leave-active {
  animation: nomodal 0.2s;
}

@keyframes modal {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes nomodal {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
</style>
