<template>
  <section class="section">
    <div class="container">
      <div class="page-header">
        <h3>User</h3>
        <button
          v-if="check === true"
          class="button primary"
          @click="
            (AddUser = true),
              $router.push({ query: { type: 'users', id: '', add: 'add' } })
          "
        >
          Add Staff
        </button>
        <button
          v-else
          class="button primary"
          disabled
          @click="
            (AddUser = true),
              $router.push({ query: { type: 'users', id: '', add: 'add' } })
          "
        >
          Add Staff
        </button>
        <div v-if="errorMessagereset" class="box-error">
          <div class="error_text">{{ errorMessagereset }}</div>
        </div>
      </div>
      <table class="table is-fullwidth" @click="closemo()">
        <thead>
          <tr>
            <th class="is-xs">Photo</th>
            <th class="text-center">Name</th>
            <th class="is-lg text-center">Email</th>
            <th class="is-md text-center">Permission</th>
            <th v-if="check === true" class="is-xs text-center">Option</th>
            <!-- <th v-if="check === true" class="is-xs">On/Off</th> -->
          </tr>
        </thead>
        <tbody>
          <tr class="td-row" v-for="(user1, idx) in user" :key="idx">
            <td data-label="Photo">
              <img class="img" :src="user1.image" />
            </td>
            <td data-label="Name" class="text-center">{{ user1.fName }}</td>
            <td data-label="Email" class="text-center">{{ user1.email }}</td>
            <td data-label="Email" class="text-center">{{ user1.role }}</td>
            <td v-if="check === true" class="is-sm">
              <div class="icon-group">
                <span @click="openUpdatemodal(user1._id)"
                  ><i class="fas fa-pen"></i
                ></span>
                <span
                  v-if="company.main == false"
                  @click="cancelUser(user1._id)"
                  ><i class="fad fa-user-slash"></i
                ></span>
                <span v-if="company.main == true" @click="deleteUser(user1._id)"
                  ><i class="fad fa-trash"></i
                ></span>
              </div>
            </td>
            <!-- <td v-if="check === true" class="is-sm">
            <label class="switch">
                    <input @click="offUser(user1._id)" type="checkbox" v-model="user1.off" value="true"/>
                    <span class="slider round"></span> </label></td> -->
          </tr>
        </tbody>
      </table>

      <div
        class="modal-bg"
        v-if="$route.query.id"
        @click="$router.push({ ...$route, query: { type: 'users', id: '' } })"
      ></div>

      <AddUser v-if="$route.query.id" :AddUser="AddUser" :user="user" />
      <AddUser v-if="AddUser" :AddUser="AddUser" />

      <!-- <component :is="AddStaff"></component> -->
    </div>
  </section>
</template>

<script>
import { GET_USERLIST } from "@/graphql/Company";
import AddUser from "../../components/mycompany/add-user";
import { OFF_USER, DELETE_USER, USER_CHANG } from "@/graphql/User";
import { GET_USER_FORM } from "../../graphql/User";
import apolloClient_Company from "@/services/apollo-client-company";
export default {
  components: {
    AddUser,
  },
  props: ["company"],
  data: () => ({
    errorMessagereset: "",
    errorMessage: "",
    AddUser: false,
    user: [],
    check: "",
    name: "",
  }),

  methods: {
    async deleteUser(i) {
      try {
        const isConfirmed = await this.$dialog.confirm();
        if (!isConfirmed) return;
        await apolloClient_Company.mutate({
          mutation: DELETE_USER,
          variables: {
            id: i,
          },
        });
        this.getUser();
      } catch ({ graphQLErrors }) {
        const errorText = graphQLErrors[0].message;
        switch (errorText) {
          case "This Account is Employer owner":
            return this.$dialog.alert("This Account is Employer owner!");
        }
      }
    },
    async cancelUser(i) {
      try {
        const isConfirmed = await this.$dialog.confirm();
        if (!isConfirmed) return;
        await apolloClient_Company.mutate({
          mutation: USER_CHANG,
          variables: {
            userId: i,
          },
        });
        this.getUser();
      } catch ({ graphQLErrors }) {
        const errorText = graphQLErrors[0].message;
        switch (errorText) {
          case "This Account is Employer owner!":
            return this.$dialog.alert("This Account is Employer owner!");
        }
      }
    },

    async offUser(i) {
      await apolloClient_Company.mutate({
        mutation: OFF_USER,
        variables: {
          id: i,
        },
      });
    },

    closemo() {
      this.AddUser = false;
      this.AddModal = false;
      if (this.$route.query.add) {
        this.$router.push({ ...this.$route, query: { type: "users", id: "" } });
      }
    },
    async checkgetUser() {
      const res = await apolloClient_Company.query({
        query: GET_USER_FORM,
      });
      this.user = res.data.userInfo;
      this.check = this.user.isRole;
      this.name = this.user.fName;
    },
    async getUser() {
      const res = await apolloClient_Company.query({
        query: GET_USERLIST,
      });
      this.user = res.data.staffList;
    },
    async openUpdatemodal(id) {
      this.AddModal = true;
      this.$router.push({ ...this.$route, query: { type: "users", id } });
    },
  },
  async created() {
    await this.checkgetUser();
    await this.getUser();
  },
  watch: {
    "$route.query": {
      handler(val) {
        if (val) {
          this.getUser();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.error_text {
  color: red;
}

.img {
  background-image: url("../../../public/images/user-avatar/user-avatar-02.png");
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
}

.add-modal {
  padding: 30px;
  background-color: #fff;
  box-shadow: -5px 0px 20px -5px rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 40%;
  z-index: 9999;
  position: fixed;
  right: 0;
  top: 0;
}

.modal-bg {
  background-color: rgba(#333, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  cursor: pointer;
}

// checkbox
.custom-checkbox {
  margin-bottom: 8px;

  label {
    margin-left: 25px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 18px;
      height: 18px;
      border: 2px solid var(--primary-color);
      transition: all ease-in-out 0.2s;
      z-index: 0;
      margin-left: -25px;
      left: 0;
    }
  }

  input {
    display: none;

    &:checked ~ label::before {
      background-color: var(--primary-color);
    }

    &:checked ~ label::after {
      content: "";
      position: absolute;
      left: -21px;
      top: 8px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
        4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
      transform: rotate(45deg);
    }

    &:checked ~ label {
      color: var(--primary-color);
    }
  }
}

hr {
  background-color: var(--border-color);
  height: 1px;
}

.btn {
  width: 100%;
  margin-top: 10px;
  box-shadow: unset;
  box-shadow: none;
  border: none;
  background-color: var(--primary-color);
  color: #fff;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: green;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: red;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
